import { FloatingNav } from "@/Components/extension/floating-navbar";
import { Toaster } from "@/Components/ui/sonner";
import { cn } from "@/lib/utils";
import ComingSoonModal from "@/Modules/ComingSoonModal";
import ManageCookies from "@/Modules/CookiesSheet";
import GlobalLoader from "@/Modules/GlobalLoader";
import { homeLinks } from "@/Pages/HomeV2/utils";
import { usePage } from "@inertiajs/react";
import AOS from "aos";
import { useEffect } from "react";
import { toast } from "sonner";
import Foooter from "./partials/Foooter";

export default function HomeLayoutV2({ wrapperClass, children, hasFooter = true }) {
    const { flash } = usePage().props;

    useEffect(() => {
        if (flash?.message) {
            toast.success(flash.message);
        } else if (flash?.error) {
            toast.error(flash.error);
        }
        return () => toast.dismiss();
    }, [flash]);

    useEffect(() => {
        AOS.init({ duration: 1200 });
        AOS.refresh();
    }, []);

    return (<>
        <div className={cn("min-h-screen h-full xl:p-8 lg:p-6 p-4 overflow-y-auto w-screen flex flex-col bg-[#b2dced]", wrapperClass)}>
            <FloatingNav navItems={homeLinks} offset={0.1} />

            <Toaster
                position="top-right"
                expand={false}
                closeButton
                richColors
            />

            {children}

            <GlobalLoader />
            <ComingSoonModal />
            <ManageCookies />
            {hasFooter && <Foooter />}
        </div>
    </>
    );
}
